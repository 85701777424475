<script>

  import { state } from '../state';

  export default {
    data: ()=>({
      state: state
    }),
    props: {
      classname: '',
      label: '',
      menu: '',
      items: [],
      menuItems: [],
      activeSubnav: null,
      section: null
    },
    computed: {
      hoverActive: function () {
        if(this.state.menu === 'desktop' && !this.state.isTouch) {
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      menuEnter(data, event) {
        if(!this.hoverActive) {
          return;
        }        
        this.activateMenu(data, 'menuEnter', event.type)
      },
      menuLeave(data, event) {
        if(!this.hoverActive) {
          return;
        }
        this.activateMenu(data, 'menuLeave', event.type)
      },
      menuClick(data, event) {

        let hasMenu = (data.item.dropDownType != 'none') ? true: false;

        // This action is Only For Tablets
        
        if(this.hoverActive) {
          return;
        }

        // This action is not applicable when there is no subnac
        
        if(!hasMenu) { 
          return;
        }

        // Prevent Default & Activate Menu

        event.preventDefault();

        this.activateMenu(data, 'menuClick', event.type);

      },
      menuKeydown(data, event) {
      
        // Enter Key is Similar to Click

        let hasMenu = (data.item.dropDownType != 'none') ? true: false;

        if(!hasMenu) { 
          return;
        }
        
        event.preventDefault();
        
        this.activateMenu(data, 'menuClick', event.type);

      },
      activateMenu(data, type, eventType) {

        let hasMenu = (data.item.dropDownType != 'none') ? true: false;
        let el = this.$refs.navElement[data.idx];

        this.$emit(
          type, {
            menu: this.menu,
            left: el.offsetLeft,
            width: el.clientWidth,
            hasMenu: hasMenu,
            mid: data.item.key,
            type: type,
            eventType: eventType,
            index: data.idx
        });
      
      }
    }
  }

</script>

<template>
  <ul
    :class="classname"
    :aria-label="label"
  >
    <li
      v-for="(item, index) in menuItems"
    >
      <a
        :href="item.url"

        :aria-expanded= "(activeSubnav == item.key) ? 'true' : (item.dropDownType != 'none') ? 'false' : null"
        :aria-haspopup="(item.dropDownType != 'none') ? 'menu' : null"
        :aria-controls="(item.dropDownType != 'none') ? 'subMenu' : null"

        @mouseenter="menuEnter({
          idx : index,
          item : item
        }, $event)"
        
        @mouseleave="menuLeave({
          idx : index,
          item : item
        }, $event)"
        
        @click="menuClick({
          idx : index,
          item : item
        }, $event)"
        
        @keydown.enter="menuKeydown({
          idx : index,
          item : item
        }, $event)"

        :class="{active : section == item.key}"
      >
        <span ref="navElement">{{item.title}}</span>
      </a>
    </li>
  </ul>
</template>
